import { graphql } from "gatsby"
import React from "react"
import MainLayout from "../components/MainLayout"
import QuizForm from "../components/QuizForm"

export default function Quiz({ data }) {
    return (
        <MainLayout>
            <QuizForm quiz={data.allDataJson.edges[0].node} />
        </MainLayout>
    )
}

export const query = graphql`
query QuizQuery {
  allDataJson {
    edges {
      node {
        layer1 {
          answers {
            ref
            text
          }
          title
        }
        layer2 {
          g1 {
            answers {
              ref
              text
            }
            title
          }
          g2 {
            answers {
              text
              ref
            }
            title
          }
          g3 {
            title
            answers {
              ref
              text
            }
          }
        }
      }
    }
  }
}
`