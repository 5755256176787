import { Button, Paper, Typography } from "@material-ui/core";
import React from "react"
import { Question } from "./QuizForm";
import { shuffleArray } from "../util"
import "./QuizQuestion.scss"

export default function QuizQuestion({ question, number, answerFunc }: { question: Question, number: number, answerFunc: (ref: string) => void }) {
    return (
        <Paper elevation={3} className="quiz-box">
            <Typography variant="overline" display="block" gutterBottom>Question {number}</Typography>
            <Typography variant="h5">{question.title}</Typography>
            <div className="answers">
                {shuffleArray(question.answers).map((q) => (
                    <Button key={q.title} style={{ margin: "8px" }} size="large" onClick={() => answerFunc(q.ref)}>{q.text}</Button>
                ))}
            </div>
        </Paper>
    )
}