import React from "react"
import { navigate } from "gatsby"
import { shuffleArray } from "../util"
import QuizQuestion from "./QuizQuestion"

export interface Quiz {
    layer1: Question[],
    layer2: {
        [key: string]: Question[]
    }
}

export interface Question {
    title: string
    answers: Answer[]
}

export interface Answer {
    text: string
    ref: string
}

const questionSelectionRatio = 1 / 3

interface RefScore {
    ref: string,
    count: number
}

interface LayerState {
    questionIndex: number
    randomizedQuestions: Question[]
    refs: RefScore[]
}

export default class QuizForm extends React.Component<{ quiz: Quiz }, {
    displayQuestionNumber: number
    layer: number
    layer1: LayerState,
    layer1EndRef: string | null
    layer2: LayerState
    layer2EndRef: string | null
}> {
    constructor(props) {
        super(props)
        this.quiz = props.quiz
        this.state = {
            displayQuestionNumber: 1,
            layer: 1,
            layer1: {
                questionIndex: 0,
                randomizedQuestions: shuffleArray(this.quiz.layer1).slice(0, Math.ceil(this.quiz.layer1.length * questionSelectionRatio)),
                refs: []
            },
            layer1EndRef: null,
            layer2: {
                questionIndex: 0,
                randomizedQuestions: [],
                refs: []
            },
            layer2EndRef: null
        }
    }
    quiz: Quiz

    incrementRef(refArray: RefScore[], ref: string) {
        let exists = false
        for (const refItem of refArray) {
            if (refItem.ref === ref) {
                refItem.count = refItem.count + 1
                exists = true
            }
        }
        if (!exists) {
            refArray.push({
                ref: ref,
                count: 1
            })
        }
    }
    highestRef(refArray: RefScore[]): RefScore {
        return shuffleArray(refArray).sort((a, b) => b.count - a.count)[0]
    }
    calculateMatch(refArray: RefScore[]): number {
        const total = refArray.reduce((acc, curr) => acc + curr.count, 0)
        return this.highestRef(refArray).count / total
    }


    answer = (ref: string) => {

        if (this.state.layer === 1) {
            const refArray = this.state.layer1.refs
            this.incrementRef(refArray, ref)

            if (this.state.displayQuestionNumber < this.state.layer1.randomizedQuestions.length) {
                this.setState({
                    displayQuestionNumber: this.state.displayQuestionNumber + 1,
                    layer1: {
                        questionIndex: this.state.layer1.questionIndex + 1,
                        randomizedQuestions: this.state.layer1.randomizedQuestions,
                        refs: refArray
                    }
                })
            } else {
                const endRef = this.highestRef(refArray).ref
                this.setState({
                    displayQuestionNumber: this.state.displayQuestionNumber + 1,
                    layer: 2,
                    layer1: {
                        questionIndex: this.state.layer1.questionIndex,
                        randomizedQuestions: this.state.layer1.randomizedQuestions,
                        refs: refArray
                    },
                    layer1EndRef: endRef,
                    layer2: {
                        questionIndex: 0,
                        randomizedQuestions: shuffleArray(this.quiz.layer2[endRef]).slice(0, Math.ceil(this.quiz.layer2[endRef].length * questionSelectionRatio)),
                        refs: []
                    }
                })
            }
        }

        if (this.state.layer === 2) {
            const refArray = this.state.layer2.refs
            this.incrementRef(refArray, ref)

            if (this.state.displayQuestionNumber < this.state.layer2.randomizedQuestions.length) {
                this.setState({
                    displayQuestionNumber: this.state.displayQuestionNumber + 1,
                    layer2: {
                        questionIndex: this.state.layer1.questionIndex + 1,
                        randomizedQuestions: this.state.layer2.randomizedQuestions,
                        refs: refArray
                    }
                })
            } else {
                const endRef = this.highestRef(refArray).ref
                this.setState({
                    layer: 3,
                    layer2: {
                        questionIndex: this.state.layer1.questionIndex,
                        randomizedQuestions: this.state.layer2.randomizedQuestions,
                        refs: refArray
                    },
                    layer2EndRef: endRef
                })
            }
        }
    }



    render() {

        if (this.state.layer === 3) {
            const layer1Match = this.calculateMatch(this.state.layer1.refs)
            const layer2Match = this.calculateMatch(this.state.layer2.refs)
            const overallMatch = (layer1Match + layer2Match) / 2
            navigate(
                `/result/${this.state.layer2EndRef}`,
                {
                    state: {
                        matchPercentage: overallMatch
                    },
                }
            )
        }

        return (
            <div>
                {this.state.layer === 1 && (
                    <QuizQuestion number={this.state.displayQuestionNumber} answerFunc={this.answer} question={this.state.layer1.randomizedQuestions[this.state.layer1.questionIndex]} />
                )}
                {this.state.layer === 2 && (
                    <QuizQuestion number={this.state.displayQuestionNumber} answerFunc={this.answer} question={this.state.layer2.randomizedQuestions[this.state.layer2.questionIndex]} />
                )}
            </div>
        )
    }
}